$(function() {

	class Build {
		constructor(target = '#build-container', sortable = true) {
			this.$ = $(target).addClass('build-container');

			this.$div = $('<div />');
			this.width = null;
			this.height = 0;

			this.$.prepend('<div class="hooks">\
								<a id="build-preview" title="Förhandsvisa" class="icon_btn fa-compress fa-2x"></a>\
								<a id="build-edit" title="Redigera" class="icon_btn fa-pencil fa-2x"></a>\
							</div>');

			this.$.append('<div class="build-add"><span class="fa-plus"></span></div>');
			this.$add = this.$.find('.build-add');

			this.$actionAdd = $('<div class="actions"><span class="fa-edit" data-action="edit"></span></div>');
			this.$actionEdit = $('<div class="actions"><span class="fa-edit" data-action="edit"></span></div>');
			this.$actionTrash = $('<span class="build__trash fa-remove"></span>');

			this.sortable = sortable;

			this.setWidth(this.$.data('width'));
			this.events();

			this.maxColumns = 8; // maxWidth of a sign = maxColumns * maxBlockWidth
			this.maxBlockWidth = 120;

			this.$.find('.build').each((k, v) => {
				let [, height] = $(v).find('.block:eq(0)').data('dimensions').split('x');
				this.height += +height;
			});

			this.empty = this.height === 0;

			this.setDimensions();
		}

		getBuildClasses() {
			let classes = [],
				increments = 240,
				max = 960,
				min = 480;

			for(let i = max; i >= min; i -= increments) classes.push(`build-${i}`);
			return classes.join(' ');
		}

		events() {

			this.$.on('click', '.build__trash', ev => {
				let $this = $(ev.currentTarget),
					$build = $this.closest('.build');

				if(confirm('Är du säker på att du vill ta bort hela denna rad?')) {
					$build.addClass('row--remove');
					setTimeout(() => {
						$build.remove();
					}, 500);
				}

				ev.preventDefault();
			});

			this.$.on('mouseover mouseout', '.build__trash', ev => {
				$(ev.currentTarget).closest('.build').toggleClass('build--remove');
			});

			this.$.on('click', '.build-add', ev => {
				mask
					.setSize('large')
					.setContent('<h4>Välj en layout</h4>\
									<div id="build-select">\
										<ul class="radioButton-holder tabs" />\
										<div class="tab-contents" />\
									</div>');

				// Loop columns
				for(let c = 1; c <= this.maxColumns; c++) {
					// If block width isn't a modulus 0 on maxBlockWidth, or 360
					// @todo: The latter needs better logic
					let blockWidth = this.width / c;
					if(blockWidth % this.maxBlockWidth !== 0 || blockWidth === 360) continue;

					// Create tabs and plan for column content
					let $tab = $(`<li class="styledRadioButton">${this.pluralization(c, 'kolumn', 'kolumner')}</li>`).appendTo(mask.$inner.find('.tabs')),
						$content = $(`<div class="tab-content" data-columns="${c}" />`).appendTo(mask.$inner.find('.tab-contents')),
						height = 0;

					if(c === 1) $tab.addClass('active');

					let build = new Build($content, false);
					build.setWidth(this.width);

					let i = this.maxBlockWidth;

					if(c === 1) {
						// Add a custom size for 240x60 in a one-column layout
						if(this.width === 240) {
							build.addBlocks(c, 60, false, false);
							height += 60;
						}

						// Possible fullwidth heights are increments of 120, with a start of 120
						for(; i <= this.width; i += 120) {
							build.addBlocks(c, i, false, false);
							height += i;
						}
					} else {
						// All other columns are only available in 30, 60, 120 heights
						for(; i >= 30; i /= 2) {
							if(blockWidth < this.maxBlockWidth) continue;

							// console.log('build.addBlocks(', c, ',', i, ')', this.width / c);
							build.addBlocks(c, i, false, false);
							height += i;
						}
					}

					build.$.on('build:select', (ev, info) => {
						this.addBlocks(info.amount, info.height, true);
						information.generate({
							content: 'Blocket har lagts till.',
							type: 'success'
						});
						mask.hide();

						this.empty = false;
						this.height += info.height;

						this.setDimensions();
					});

					if(height === 0) {
						$tab.remove();
						$content.remove();
					}
				}

				// Remove all .build-add-buttons
				mask.$inner.find('.build-add').remove();

				let $tabContents = $('.tab-content', mask.$inner.find('.tab-contents'));
				$('.tabs', mask.$inner).on('click', 'li', function(ev) {
					let $this = $(this),
						index = $this.index();

					$this.siblings().removeClass('active');
					$this.addClass('active');

					$tabContents.hide();
					$tabContents.eq(index).show();
				});

				mask.show();
			});

			this.$.on('click', '[data-action]', ev => {
				let $this = $(ev.currentTarget),
					$build = $this.closest('.build'),
					$block = $this.closest('.block');

				let companies = [],
					selfCompany = $('[name="company_id"]').val() || $this.closest('.tab-contents').data('company');

				// Add current company
				if(typeof selfCompany !== 'undefined') {
					companies.push(selfCompany);
				}

				$('[data-company]').each((k, v) => {
					companies.push($(v).data('company'));
				});

				$.ajax({
					type: 'GET',
					url: '/logos',
					data: {
						companies,
						dimensions: $block.data('dimensions')
					}
				}).done(response => {
					mask
						.setSize('large')
						.setContent('<h4>Välj en under-entreprenads logotyp ...</h4>\
									<div class="content">\
										<div id="companies" />\
										<div id="results" class="logos" />\
										<h3>... eller välj en bild</h3>\
										<div class="dropzone"></div>\
									</div>');

					// Inject a remove button if block already has an image
					if($block.find('img').length) {
						mask.$inner.find('.content').append('<button class="btn btn--abort fa-remove push-small-top" id="remove-block">Ta bort bild</button>');
					}

					let $drop = mask.$inner.find('.dropzone');
					$drop.dragDrop({
						maxAmount: 1,
						placeholder: 'Drag och släpp, eller välj filer att ladda upp.'
					});

					$drop.data('dragdrop').settings.onUpload = (file, dataUrl) => {
						let [width, height] = $block.data('dimensions').split('x');

						let $img = $('<img />')
							.attr({ src: dataUrl, alt: '' })
							.data({ id: null, height: height, data: dataUrl });

						mask.$inner.append($img);
						$img.trigger('click');
					};

					mask.$inner.off('.oc').find('#companies').html(response);
					mask.show();

					mask.$inner.on('click.oc', '#remove-block', ev => {
						$block.find('img').remove();
						mask.hide();
					});

					// Inject selected logo appropriately
					mask.$inner.on('click.oc', 'img', ev => {
						let $img = $(ev.currentTarget).clone(true),
							logos = $build.data('logos') || {};

						$block.empty()
							.append($img)
							.append(this.$actionEdit.clone());

						if($block.index() === $block.siblings('.block').length) {
							this.$actionTrash.clone().appendTo($block);
						}

						logos[$block.index()] = {
							id: $img.data('id'),
							height: $img.data('height'),
							data: $img.data('id') === null ? $img.data('data') : null
						};

						$build.data('logos', logos);

						information.generate({
							content: 'Logotypen har lagts till.',
							type: 'success'
						});
						mask.hide();
					});
				});
			});

			this.$.on('click', '.build', ev => {
				this.$.trigger('build:select', $(ev.currentTarget).data('info'));
			});

			this.sortable && this.$.sortable({
				items: '.build'
			});
		}

		pluralization(amt, singular, plural) {
			return amt +' '+ (amt === 1 ? singular : plural);
		}

		setDimensions() {
			if(typeof this.width !== 'undefined') {
				this.$.attr('data-dimensions', `${this.width} x ${this.height}`);
			}
			return this;
		}

		isEmpty() {
			return this.empty;
		}

		setWidth(width) {
			this.width = +width;

			width && this.$.removeClass(this.getBuildClasses()).addClass(`build-${width}`);
			return this;
		}

		addBlocks(amount, height = 30, actions = false, trash = true) {
			let $build = this.$div.clone().addClass(`build build--${amount}`);
			$build.data('info', { amount, height });

			// Special case - 120 x 120 doesn't exist
			if(height === 120 && this.width / amount === 120) return;

			for(let i = 0; i < amount; i++) {
				let $block = this.$div.clone()
								.addClass(`block block-${height}`)
								.attr('data-dimensions', `${this.width / amount}x${height}`);

				if(actions) {
					$block.html(this.$actionAdd.clone());
				}

				if(trash && i === amount - 1) {
					this.$actionTrash.clone().appendTo($block);
				}

				$block.appendTo($build);
			}

			this.$.append($build);
			this.$.append(this.$add.detach());
		}
	}


	window.Build = Build;

});